import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/BaseService';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class DataService extends BaseService {

    jobPostUUid: Observable<any>;
    jobContent: any;
    jobList: any;
    candidateUuid: any;
    candidateData: Observable<any[]>;
    location: any = null;
    userdata: any;
    // socialMedia: string;
    code: string;
    context: string;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private _snackBar: MatSnackBar
    ) { super() }

    init() { }

    setData(content) {
        this.jobContent = content;
    }

    setJobPostUuid(content) {
        this.jobPostUUid = content.uuid;
    }

    getData(): any {

        if (this.jobContent) {
            return this.jobContent;
        }
    }

    setCandidateData(content) {
        this.candidateData = content;
    }

    getCandidateData(): Observable<any> {
        if (this.candidateData) {
            return of(this.candidateData);
        }
    }

    // socialLogin(signInData) {

    //     signInData.social_login = signInData.provider;
    //     // for signing up with social media
    //     signInData.password = signInData.id;
    //     // signInData.email=signInData.email;

    //     // if (context === 'candidate') {

    //         signInData.firstname = signInData.firstName;
    //         signInData.lastname = signInData.lastName;
    //         localStorage.setItem('social_login', signInData.provider);

    //         console.log(signInData)
    //         this.authService.signupCandidate(signInData).subscribe(userdata => {

    //             localStorage.setItem('context', 'candidate');

    //             this.authService.signedName = userdata.firstname + " " + userdata.lastname;
    //             this.authService.isValidCandidate = userdata.isvalid;
    //         },
    //             error => {

    //                 signInData.socialLogin = signInData.provider;
    //                 signInData.context = "candidate";

    //                 this.authService.sociallogin(signInData).subscribe((data) => {

    //                     this.authService.getCandidateProfile().subscribe((data) => {

    //                         this.authService.signedName = data.firstname + " " + data.lastname;
    //                         this.authService.isValidCandidate = data.isvalid;
    //                     })
    //                 },
    //                     error => {
    //                         this._snackBar.open("Aνεπιτυχής σύνδεση ως υποψήφιος. Υπάρχει ήδη λογαριαμός μέσω " + signInData.socialLogin + " ως επιχείρηση με αυτά τα στοιχεία", null, {
    //                             duration: 9000,
    //                             verticalPosition: 'top', horizontalPosition: 'center',
    //                             panelClass: ['error-dialog']
    //                         });
    //                     })
    //             });
    //     }
    //     else {

    //         this.authService.signupEmployer(signInData).subscribe(userdata => {

    //             localStorage.setItem('context', context);
    //             this.authService.isValidCompany = true;
    //             this.authService.signedName = userdata.name;
    //         },
    //             error => {

    //                 signInData.context = "company";
    //                 signInData.socialLogin = signInData.provider;

    //                 this.authService.sociallogin(signInData).subscribe((data) => {

    //                     this.authService.getCompanyInfo(this.authService.getUUID()).subscribe((data) => {

    //                         this.authService.isValidCompany = data.isValid;
    //                         this.authService.signedName = data.name;
    //                     })
    //                 },
    //                     error => {

    //                         // pop up error message 
    //                         this._snackBar.open("Aνεπιτυχής σύνδεση ως επιχειρήση. Υπάρχει ήδη λογαριαμός μέσω " + signInData.socialLogin + " ως υποψήφιος με αυτά τα στοιχεία", null, {
    //                             duration: 9000,
    //                             verticalPosition: 'top', horizontalPosition: 'center',
    //                             panelClass: ['error-dialog']
    //                         });

    //                     })
    //             });
    //     }
    // }

}

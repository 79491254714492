import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { RouterLink, Router } from '@angular/router';
import { MetadataService } from '../../MetadataService';
import { BaseService } from 'src/app/BaseService';
import { DataService } from 'src/app/dataService';
import { MatSnackBar } from '@angular/material';
import { SocialAuthService } from 'src/app/social-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted = false;
  errormessage: boolean;
  metadata: any;
  message: any;
  shownavbar: boolean;
  signin: boolean;
  showLoginForm = false;
  socialMedia: string;
  context:string = 'candidate';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private dataService: DataService, 
    private _snackBar: MatSnackBar,
    private socialAuth: SocialAuthService
  ) { }

  ngOnInit() {
    this.errormessage = false;
    this.signin = (localStorage.getItem('uuid') != null);

    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit() {
  }

  get formControls() {
    return this.loginForm.controls;
  }

  login() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    // this.loginForm.value.context="both";
    this.authService.login(this.loginForm.value)
      .subscribe(
        user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes 
          //get metadata 
          this.metadataService.getMetaData().subscribe((data: any) => {
            this.metadata = data;
            this.signin = true;

          });

          if (user.context ==='company') {
            this.authService.getCompanyInfo(this.authService.getUUID()).subscribe((data)=>{
              this.authService.isValidCompany = data.isValid;
              this.authService.signedName = data.name;
               $("#LoginModal").modal("hide");
            });
          }
          else {
            this.authService.getCandidateProfile().subscribe((data)=>{
              this.authService.signedName = data.firstname +" "+ data.lastname;
              this.authService.isValidCandidate = data.isvalid;
              $("#LoginModal").modal("hide");
            })

          }

        }, error => {

          this.message = error.error.message;

          // if (this.message == "User email doesn't match.") {
          //   this.message = "Αυτό το email δεν υπάρχει!"
          // }
          if (this.message == "Candidate does not exist.") {
            this.message = "Δεν βρέθηκε υποψήφιος με αυτό το email. Βεβαιωθείτε ότι έχετε δημιουργήσει λογαριασμό και ελέγξτε πάλι το email που εισάγετε!"
          }
          if (this.message == "User password doesn't match.") {
            this.message = "Ο κωδικός δεν ταιριάζει με το email!"
          }
        });
    if (this.loginForm) {
      this.loginForm.reset();
      this.loginForm.markAsUntouched();
      this.isSubmitted = false;
      this.message = "";
    }
  }

  goToGemi(){
    this.authService.getGemiAuthUrlandRedirectToGemi();
  }

  goToLinkedIn(){
    this.authService.getLinkedInAuthUrlAndRedirectToLinkedIn();
  }

  signInWithFacebookOrGoogle(provider){
    $("#LoginModal").modal("hide");
    this.socialAuth.signInWithSocial(provider);
  }

  // socialAuth(social) {
  //   // stores the type of the social media 
  //   this.dataService.socialMedia = social;
  //   $("#LoginModal").modal("hide");
  //   $("#socialSignInModal").modal("show");
  // }

}

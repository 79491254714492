import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { AllJobsService } from '../AllJobsSevice';
import { AuthService } from 'src/app/auth.service';

@Injectable()
export class ProfileResolver implements Resolve<any> {

    companyuuid: any;

    constructor(private authService: AuthService) 
    {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {

        this.companyuuid = route.params.uuid ? route.params.uuid : this.authService.getUUID();

        return this.authService.getCompanyInfo(this.companyuuid).pipe(     
            take(1),
            catchError(error => {
                return throwError(error);
            }),
            map((resp: any) => {
                if (resp) {
                    return resp;
                } else {
                    return [];
                }
            })
        );
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { EmployersComponent } from './employers/employers.component';
import { EmployersModule } from './employers/employers.module';
import { AuthGuardService } from './auth-guard.service';
import { JobListingComponent } from './job-listing/job-listing.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { ProfileResolver } from './employer-profile/employer-profile.resolver';
import { ProfileComponent } from './employer-profile/employer-profile.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { CandidatesListComponent } from './candidates-list/candidates-list.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { LoginLinkedInComponent } from './login-linked-in/login-linked-in.component';
import { OauthGemiForBusinessComponent } from './oauth-gemi-for-business/oauth-gemi-for-business.component';
import { AdministratorLoginComponent } from './administrator-login/administrator-login.component';
import { AdministratorCvHandlingComponent } from './administrator-cv-handling/administrator-cv-handling.component';
import { AdministratorCvHandlingResolver } from './administrator-cv-handling/administrator-cv-handling.resolver';
import { AdministratorCreateMediatorsComponent } from './administrator-create-mediators/administrator-create-mediators.component';
import { EmailFormForPassChangeComponent } from './forgot-your-password/email-form-for-pass-change/email-form-for-pass-change.component';
import { ResetPasswordFormComponent } from './forgot-your-password/reset-password-form/reset-password-form.component';
import { AdministratorJobPostHandlingComponent} from './administrator-job-post-handling/administrator-job-post-handling.component';
import { AdministratorCompanyHandlingComponent } from './administrator-company-handling/administrator-company-handling.component';
// import { AdministratorCompanyHandlingResolver } from './administrator-company-handling/administrator-company-handling.resolver';

const routes: Routes = [
  { path: "", redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginLinkedInComponent },
  { path: 'home', component: HomeComponent },
  { path: 'forgotPass/emailForm', component: EmailFormForPassChangeComponent},
  { path: 'forgotPass/resetPassForm' , component: ResetPasswordFormComponent},
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'job-details/:uuid', component: JobDetailsComponent },
  { path: 'candidates/list', component: CandidatesListComponent },
  { path: 'job-listing', component: JobListingComponent },
  { path: 'employer/profile/:uuid', component: ProfileComponent, resolve: { data: ProfileResolver } },
  { path: 'candidate/profile/:uuid',canActivate: [AuthGuardService],component: CandidateProfileComponent },
  { path: 'candidates', canActivate: [AuthGuardService], loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule) },
  { path: 'employers', canActivate: [AuthGuardService], loadChildren: () => import('./employers/employers.module').then(m => m.EmployersModule) },
  { path: 'oauthGemi',component:OauthGemiForBusinessComponent},
  { path: 'employers', canActivate: [AuthGuardService], loadChildren: () => import('./employers/employers.module').then(m => m.EmployersModule) },
  { path: "administrator", redirectTo: 'administrator/login', pathMatch: 'full' },
  { path: "administrator/login", component: AdministratorLoginComponent },
  { path: "administrator/cvHandling/:uuid",component: AdministratorCvHandlingComponent, resolve: { data: AdministratorCvHandlingResolver } },
  // { path: "administrator/companyHandling", component: AdministratorCompanyHandlingComponent , resolve: { adminResolverCompany: AdministratorCompanyHandlingResolver} },
  { path: "administrator/companyHandling", component: AdministratorCompanyHandlingComponent },
  { path: "administrator/createMediators/:uuid",component: AdministratorCreateMediatorsComponent },
  { path: "administrator/jobPostHandling", component: AdministratorJobPostHandlingComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { NgModule, AfterViewInit } from "@angular/core";
import { MatButtonModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DataService } from './dataService';
import { shareReplay, map } from 'rxjs/operators';
import { MetadataService } from './MetadataService';
import { MessageService } from './message.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@NgModule({
  imports: [MatIconModule, MatButtonModule], // note the imports 
  exports: [MatIconModule, MatButtonModule], // and the exports
})
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'Jobs-fe';
  metadata$: Observable<any>;
  locations$: Observable<Array<{ id: number | string; text: string; }>>;
  locations: any;
  isCandidate: boolean;
  isCompany:boolean;
  modalMessage:string;
  showOverlay = true;
  currentYear: any;
  popularLocations: any[];
  administratorUrlsRegex: RegExp = new RegExp('\/administrator\/');
  administratorUrlRegexWithoutLastSlash = new RegExp('\/administrator');
  constructor(
    private dataService: DataService,
    private metadataService: MetadataService,
    private router: Router,
    private authService: AuthService,
    private messageService:MessageService
  ) { 
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }

  ngOnInit() {
    this.currentYear= new Date().getFullYear();

    this.isCandidate = this.authService.isCandidate();
    this.isCompany = this.authService.isCompany();

    this.authService.getJobPostsPopularLocations().subscribe(res=>{
      this.popularLocations = res;
    });

    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.locations$ = this.metadata$.pipe(
      map(metadata => metadata.locations.map(location => ({ id: location.id, text: location.name })))
    );

    this.locations$.subscribe((data: any) => {
      this.locations = data;
    });
  }

  ngAfterViewInit() {
    // import('../assets/js/script.js');
  }
  // navigation interceptor for spinner loading visible 
  navigationInterceptor(event: RouterEvent){

    if (event instanceof NavigationStart) {
      //display the correct header and footer for admin components
      (this.administratorUrlsRegex.test(event.url) || this.administratorUrlRegexWithoutLastSlash.test(event.url)) ? this.authService.isAdmin = true : this.authService.isAdmin = false;
      console.log("isAdmin:" +this.authService.isAdmin);
      this.showOverlay = true;

    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  jobPostsByLocation(location) {
    let x = this.locations.find(locItem => locItem.text === location);
    this.router.navigate(['/job-listing'], { queryParams: { locationId: x.id}  });
 
    window.scrollTo(1000, 300)
  }
  onUnsignedCandidateListing(){
    this.modalMessage = "Για να έχετε πρόσβαση στην λίστα των υποψηφίων για εργασία πρέπει να έχετε συνδεθεί ως επιχείρηση.";
    this.messageService.setMessageForLoginAndRegisterModal(this.modalMessage);
    $('#MessageModal').modal('show');
  }
  onUnsignedPostAJob(){
    this.modalMessage = "Για να αναρτήσετε μια θέση εργασίας πρέπει να έχετε συνδεθεί ως επιχείρηση.";
    this.messageService.setMessageForLoginAndRegisterModal(this.modalMessage);
    $('#MessageModal').modal('show');
  }

  searchForJob(){
    this.router.navigate(["/job-listing"]);
    window.scroll(0, 0);
  }

  companyJobListing(){
    this.router.navigate(["/employers/job-listing", "all"]);
    window.scroll(0, 0);
  }

  companyPostAJob(){
    this.router.navigate(["/employers/post-a-job"]);
    window.scroll(0, 0);
  }

  searchForCandidate(){
    this.router.navigate(["/candidates/list"]);
    window.scroll(0, 0);
  }

  faq(){
    this.router.navigate(["/faq"]);
    window.scroll(0, 0);
  }

}

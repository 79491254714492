import { EmployersRoutingModule } from './employers-routing.module';
import { NgModule } from '@angular/core';

import { EditorModule } from '@tinymce/tinymce-angular';
import { EmployersComponent } from './employers.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMenuModule, MatTabsModule, MatIconModule, MatButtonModule, MatSidenavModule, MatListModule, MatToolbarModule, MatNativeDateModule, MatDatepickerModule, MatCheckboxModule, MatCardModule, MatFormFieldModule, MatInputModule, MatRadioModule } from '@angular/material';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { CommonModule } from '@angular/common';
import { PostAJobComponent } from './post-a-job/post-a-job.component';
import { MatSelectModule } from '@angular/material/select'
import { NgSelect2Module } from 'ng-select2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobListingComponent } from './job-listing/job-listing.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditPostJobComponent } from './edit-post-job/edit-post-job.component';
import { AppliedCandidatesListComponent } from './applied-candidates-list/applied-candidates-list.component';
import { AlertMessagesModule } from '../messages-helpers/messages-helpers.module';
import { PaginationModule } from '../pagination/pagination.module';
import { PaginationBsModule } from '../pagination-bs/pagination-bs.module';

@NgModule({
  declarations: [
    EmployersComponent,
    CompleteProfileComponent,
    PostAJobComponent,
    JobListingComponent,
    EditPostJobComponent,
    AppliedCandidatesListComponent
  ],
  imports: [
    EditorModule,
    CommonModule,
    EmployersRoutingModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatTabsModule,
    MatMenuModule,
    MatSelectModule,
    NgSelect2Module,
    NgbModule,
    NgSelectModule,
    AlertMessagesModule,
    PaginationModule,
    PaginationBsModule
  ],
  exports: [MatIconModule, MatButtonModule, ReactiveFormsModule, MatSidenavModule, MatListModule, MatToolbarModule],
  providers: [],
})

export class EmployersModule { }
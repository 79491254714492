import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MetadataService } from '../MetadataService';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from '../dataService';
import { shareReplay, map } from 'rxjs/operators';
import { AllJobsService } from '../AllJobsSevice';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { ConstantsService } from '../ConstantsService';


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  fileUrl: string;
  postUuid: any;
  userInfo: any;
  postJobInfo$: Observable<any[]>;
  relatedPostJobs$: Observable<any[]>;
  metadata$: Observable<any>
  content: any;
  relatedJobs: any;
  jobList: FormGroup;
  locations$: Observable<Array<{ id: number; text: string; }>>;
  jobLocation$: Observable<{ id: number; text: string; }>;
  searchJobByLocationTitle: FormGroup;
  employment_types$: Observable<Array<{ id: number; name: string; }>>;
  updatedate$: Observable<any>;
  types: any[];
  industries$: Observable<Array<{ id: number; name: string; }>>;
  isSigned: any;
  cv: File = null;
  jobUuid: String;
  currentUserUUid: any;
  hasApplied: boolean;
  candidateApplication: any;
  test: boolean = false;
  message: string = "✓ Επιτυχής καταχώρηση αίτησης";
  applydate: any;
  cvMessage: string;
  cvForTheJob: any;
  postJobForm: FormGroup;
  pageOfItems: Array<any>;
  relatedPostJobs: any;
  activationsStatus;

  // used for the alert message component
  alert;

  // used for the confirm modal component
  confirmModaltitle: string;
  confirmModalbody: string;
  selectedStatus: string;

  // used for the pagination component
  currentPage: number;
  pageSize: number = 3;
  firstPage: number = 1;

  constructor(
    private authService: AuthService,
    private metadataService: MetadataService,
    private router: Router,
    private allJobsService: AllJobsService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private constants: ConstantsService
  ) { }

  ngOnInit() {
    this.currentUserUUid = this.authService.getUUID();

    this.route.params.subscribe((params) => {

      this.jobUuid = params.uuid;
      this.authService.getJobDetailsByuuid(this.jobUuid).subscribe((jobDetails) => {
        this.content = jobDetails;
        this.content.benefits = this.content.benefits.replace('<ul>', '<ul id="benefitsList" class="list-marked-3">');
        this.content.prerequisites = this.content.prerequisites.replace('<ul>', '<ul id="prerequisitesList" class="list-marked-3">');

        if (this.authService.isCandidate()) {
          this.authService.getJobApplicationInfo(this.content.uuid).subscribe((data) => {
            this.hasApplied = true;
            this.candidateApplication = data;
          },
            (error) => {
              this.hasApplied = false;
            });
        }

        if (this.authService.isCompany() && this.authService.getUUID() === this.content.company_uuid) {
          this.getCompanyActivationStatusByuuid(this.content.company_uuid);
        }

        if (this.content.company_logoId) {
          this.content.logo = this.authService.getLogo("company", "logo", this.content.company_uuid, new Date().getTime().toString());
        }

        this.getMetadata();
        this.currentPage = this.firstPage;
        this.getRelatedJobPosts();

        this.jobLocation$ = this.metadata$.pipe(
          map(
            metadata => metadata.locations.map(location => ({ id: location.id, text: location.name })).find(location => location.id === this.content.location_id)
          ))
        this.jobLocation$.subscribe((location) => {
          this.content.location_name = location.text;
        })
      })
    });

    if (this.authService.isAuthenticated$ && this.authService.isCandidate()) {
      this.authService.getCandidateProfile().subscribe((res) => {
        this.userInfo = res;
        this.cvForTheJob = this.userInfo.cv_id;
      });
    }

    this.authService.isAuthenticated$.subscribe((value: any) => {
      this.isSigned = value;
    });
  }

  getCompanyActivationStatusByuuid(uuid) {
    this.authService.getCompanyActivationStatus(uuid).subscribe(res => {
      this.activationsStatus = res;
      // check company's activation status
      switch (this.activationsStatus) {
        case "disapproved":
          this.alert = 5;
          return;
        case "pending":
          this.alert = 4;
          return;
        case "approved":
          // check job post's activation status
          if (this.content.activation_status === "disapproved") {
            this.alert = 6;
          }
          if (this.content.activation_status === "pending") {
            this.alert = 7
          }
      }
    })
  }

  getMetadata() {
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.locations$ = this.metadata$.pipe(
      map(
        metadata => metadata.locations.map(location => ({ id: location.id, text: location.name }))
      ))

    this.employment_types$ = this.metadata$.pipe(
      map(
        metadata => metadata.employment_types.map(employment_type => ({ id: employment_type.id, text: employment_type.name }))
      )
    );

    this.industries$ = this.metadata$.pipe(
      map(metadata => metadata.industries.map(industry => ({ id: industry.id, text: industry.name })))
    );
  }

  getRelatedJobPosts() {

    if (this.content.industry_id) {

      this.currentPage = this.currentPage - 1;
      this.relatedPostJobs$ = this.allJobsService.getRelatedJobs(this.content.industry_id, this.currentPage, this.pageSize).pipe(shareReplay(1));
      this.relatedPostJobs$.subscribe((data: any) => {

        this.relatedPostJobs = data;
        this.relatedJobs = data.content;
        this.relatedJobs.forEach(item => {
          //get company logo for every item on job list
          item.logo = this.authService.getLogo("company", "logo", item.company_uuid, new Date().getTime().toString());

          this.updatedate$ = item.creation_date;
        });
        this.getDataForRelatedJobs();

      }, error => {
        console.log(error.error.message);
      });
    }
    else {
      this.relatedPostJobs = null;
      this.relatedJobs = [];
    }
  }

  getDataForRelatedJobs() {
    //compine two observables relatedJobs and metadata
    this.locations$.subscribe((locations: any[]) => {
      this.relatedJobs.forEach(item => {
        let x = locations.find(locItem => locItem.id === item.location_id);
        if (x) {
          item.location_name = x.text;
        }
      });
    })

    this.employment_types$.subscribe((employment_types: any[]) => {
      this.relatedJobs.forEach(item => {
        let x = employment_types.find(locItem => locItem.id === item.employment_type_id);

        if (x) {
          item.employment_types_name = x.text;
        }
        else {
          item.employment_types_name = "Μη διαθέσιμο";
        }

      });
    })
  }

  //change job details(called on related job click)
  jobDetails(uuid: any) {
    window.scrollTo(200, 200);
    this.router.navigate(['job-details', uuid]);
  }

  // for post file
  handleCVInput(files: FileList) {
    this.cv = files.item(0);

    const types = [
      { type: this.constants.PDF },
      { type: this.constants.DOC },
      { type: this.constants.DOCX }];

    var validType = types.some(t => t.type === this.cv.type); //allow only files
    var validSize = this.cv.size < 2 * this.constants.ONE_MB; //max allowed file size is 2MB
    var validName = this.cv.name.length < 255; //max allowed file name is 255 characters

    if (validSize && validType && validName) {
      if (this.userInfo.cv_id) {
        this.cvForTheJob = this.cv.name;
      }
      $("#cv_id").html(this.cv.name);
      this.cvMessage = null;
    }
    else {
      var errors = [validType ? null : "Λάθος τύπος αρχείου. Επιτρεπτοί τύποι: .pdf, .doc, .docx.",
      validSize ? null : "Το μέγεθος του αρχείου πρέπει να είναι μέχρι 2 MB.",
      validName ? null : "Το όνομα του αρχείου πρέπει να έχει μέχρι 255 χαρακτήρες."]
      this.cvMessage = "* Σφάλματα: " + errors.filter(Boolean).join(' ');
    }
  }

  onApply(applyForm) {
    if (!this.cvMessage) {
      if (this.cv || this.userInfo.cv_id) {
        let applyJob = {
          jobPostUuid: this.content.uuid,
          coverLetter: applyForm.value.description,
          cvId: this.cv ? this.cv.name : this.userInfo.cv_id
        }

        if (this.userInfo.cv_id && this.userInfo.cv_id === this.cvForTheJob) {

          this.authService.postApplyJob(applyJob, this.authService.getUUID())
            .subscribe((res: any) => {
              this.authService.uploadApplicationJobCv(null, applyJob.jobPostUuid)
                .subscribe((res: any) => {
                  this.test = true;
                  this.authService.getJobApplicationInfo(this.content.uuid).subscribe((data) => {

                    this.hasApplied = true;
                    this.candidateApplication = data;
                    this.authService.getJobDetailsByuuid(this.jobUuid).subscribe((jobDetails) => {
                      this.content = jobDetails;
                      this.content.benefits = this.content.benefits.replace('<ul>', '<ul class="list-marked-3">');
                      this.content.prerequisites = this.content.prerequisites.replace('<ul>', '<ul class="list-marked-3">');
                    });
                  },
                    error => {
                      console.log(error);
                    });
                })
            });
        }

        else {
          this.authService.postApplyJob(applyJob, this.authService.getUUID()).subscribe((res: any) => {
            this.authService.uploadApplicationJobCv(this.cv, applyJob.jobPostUuid)
              .subscribe((res: any) => {

                this.test = true;
                this.authService.getJobApplicationInfo(this.content.uuid).subscribe((data) => {
                  this.authService.getJobDetailsByuuid(this.jobUuid).subscribe((jobDetails) => {
                    this.content = jobDetails;
                    this.content.benefits = this.content.benefits.replace('<ul>', '<ul class="list-marked-3">');
                    this.content.prerequisites = this.content.prerequisites.replace('<ul>', '<ul class="list-marked-3">');
                  });
                  this.hasApplied = true;
                  this.candidateApplication = data;
                });
              })
          })
        }
        //na mpei mesa sta subscribes!!!!
        $("#applyModal").modal("hide");
        this.message = "✓ Επιτυχής καταχώρηση αίτησης";
        this.openSnackBar(this.message);
      }
      else {
        this.cvMessage = "* Πρέπει να προσθέσετε βιογραφικό."
      }
    }
  }

  editPost() {
    this.router.navigate(['employers/edit-post-job', this.content.uuid]);
  }

  //popup message
  openSnackBar(message) {
    this._snackBar.open(message, null, {
      duration: 4000,
    });
  }

  companyprofile() {
    this.router.navigate(['employer/profile', this.content.company_uuid]);
  }

  downLoadCv(candidateUuid) {
    const data = this.authService.downloadFile("candidate", "cv", new Date().getTime().toString(), candidateUuid);
    this.fileUrl = data;
  }

  appliedCandidatesList() {
    this.router.navigate(['applied/candidates', this.content.uuid, this.content.company_uuid]);
  }

  // if candidate profile is not valid
  navigateToCompleteProfile() {
    $("#notValidCanditateModal").modal("hide");
    this.router.navigate(['/candidates/complete-profile']);
  }

  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.getRelatedJobPosts();
  }

  onConfirmModal() {
    switch (this.selectedStatus) {
      case 'published':
      case 'closed':
        this.authService.updateJobStatus(this.content.uuid, this.selectedStatus).subscribe(res => {

          this.authService.getJobDetailsByuuid(this.jobUuid).subscribe((jobDetails) => {
            this.content = jobDetails;
            this.content.benefits = this.content.benefits.replace('<ul>', '<ul class="list-marked-3">');
            this.content.prerequisites = this.content.prerequisites.replace('<ul>', '<ul class="list-marked-3">');
          });

          $("#confirmModal").modal("hide");

          this.message = this.selectedStatus === 'published' ? "✓ Η ανάρτηση δημοσιεύτηκε επιτυχώς" : "✓ Η ανάρτηση αποσύρθηκε επιτυχώς";
          this.openSnackBar(this.message);
        });
        break;
      case 'delete':
        this.authService.deletePostJob(this.jobUuid).subscribe((res) => {
          $("#confirmModal").modal("hide");

          this.message = "✓ Επιτυχής διαγραφή ανάρτησης";
          this.openSnackBar(this.message);
          this.router.navigate(['/employers/job-listing', "all"]);
        });
        break;
    }
  }

  // set messages for confirm modal
  // open confirm modal
  openModal(status) {
    this.selectedStatus = status;
    switch (this.selectedStatus) {
      case 'published':
        this.confirmModaltitle = 'Δημοσιεύση Ανάρτησης';
        this.confirmModalbody = 'Είστε σίγουρος ότι θέλετε να δημοσιεύσετε την ανάρτηση;';
        break;
      case 'delete':
        this.confirmModaltitle = 'Διαγραφή Ανάρτησης';
        this.confirmModalbody = 'Είστε σίγουρος ότι θέλετε να διαγράψετε την ανάρτηση;';
        break;
      case 'closed':
        this.confirmModaltitle = 'Απόσυρση Αγγελίας';
        this.confirmModalbody = 'Είστε σίγουρος ότι θέλετε να αποσύρετε την αγγελία;';
        break;
    }
    $('#confirmModal').modal('show');
  }
}


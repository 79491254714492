import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { MetadataService } from '../../MetadataService';
import { Observable, forkJoin } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { ConstantsService } from 'src/app/ConstantsService';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit {

  submitted = false;
  completeEmployerForm: FormGroup;
  showLoading = true;
  companyData = null;
  metadata$: Observable<any>;
  logo: File = null;
  logo_id: String = null;
  industries$: Observable<Array<{ id: number; text: string; }>>;
  locations$: Observable<Array<{ id: number; text: string; }>>;
  fileUrl: any = null;
  numbers: Array<number> = [];
  logoMessage: string;
  initEditorDesc: {};
  mailExists:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private constants: ConstantsService) {
      this.initEditorDesc = {
        base_url: '/tinymce',
        suffix: '.min',
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
      };
  }


  ngOnInit() {

    this.yearList();
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));
    this.industries$ = this.metadata$.pipe(
      map(metadata => metadata.industries.map(industry => ({ id: industry.id, text: industry.name })))
    );
    this.locations$ = this.metadata$.pipe(
      map(metadata => metadata.locations.map(location => ({ id: location.id, text: location.name })))
    );

    this.authService.getCompanyInfo(this.authService.getUUID()).subscribe((data) => {

      this.companyData = data;

        this.completeEmployerForm =
          this.formBuilder.group({
            description: new FormControl(this.companyData.description?this.companyData.description:"", []),
            number_employees: new FormControl(this.companyData.number_employees?this.companyData.number_employees:"", [Validators.pattern(this.constants.POSITIVE_NOT_ZERO_REGEX)]),
            year_founded: new FormControl(this.companyData.year_founded?this.companyData.year_founded:"", [Validators.pattern(this.constants.POSITIVE_OR_ZERO_REGEX)]),
            motto: new FormControl(this.companyData.motto?this.companyData.motto:"", [Validators.maxLength(255)]),
            linked_in_url: new FormControl(this.companyData.linked_in_url?this.companyData.linked_in_url:"", [Validators.pattern(this.constants.URL_REGEX), Validators.maxLength(255)]),
            facebook_url: new FormControl(this.companyData.facebook_url?this.companyData.facebook_url:"", [Validators.pattern(this.constants.URL_REGEX), Validators.maxLength(255)]),
            website: new FormControl(this.companyData.website?this.companyData.website:"", [Validators.pattern(this.constants.URL_REGEX), Validators.maxLength(255)]),
            industry_id: new FormControl(this.companyData.industry_id?this.companyData.industry_id:"", []),
            location_id: new FormControl(this.companyData.location_id?this.companyData.location_id:"", []),
            logo_id: (new FormControl(this.companyData.logo_id?this.companyData.logo_id:null, [])),
            name: (new FormControl(this.companyData.name, [Validators.required, Validators.maxLength(50)])),
            email:(new FormControl(this.companyData.email?this.companyData.email:"",[Validators.required, Validators.maxLength(50)]))
          });

    })
  }

  get formControls() {
    return this.completeEmployerForm.controls;
  }

  completeEmployer() {
    this.submitted = true;

    // stop the process here if form is invalid
    if (this.completeEmployerForm.invalid) {
      console.log("invalid complete form");
      window.scroll(100, 500);
      return;
    }

    if (this.logo != null) {

      forkJoin([this.authService.completeEmployer(this.completeEmployerForm.value,this.authService.getUUID()),
      this.authService.postFile(this.logo, "logo")])
        .subscribe((res) => {
          this.authService.isValidCompany = res[0].isValid;
          this.authService.signedName = res[0].name;
          this.router.navigate(['employer/profile', this.authService.getUUID()]);
        },
          error => {

            if(error.status === 400){
              this.mailExists = true;
              $('html, body').scrollTop($("#title").offset().top);
            }            
          });
    }
    else {

      this.authService.completeEmployer(this.completeEmployerForm.value,this.authService.getUUID())
        .subscribe(user => {
          this.authService.isValidCompany = user.isValid;
          this.authService.signedName = user.name;
          this.router.navigate(['employer/profile', this.authService.getUUID()]);
        },
          error => {
            
            if(error.status === 400){
              this.mailExists = true;
              $('html, body').scrollTop($("#title").offset().top);
            }
          });
    }
  }

  handleLogoInput(files: FileList) {
    this.logo = files.item(0);
    const types = [
      {type: this.constants.JPEG}, 
      {type: this.constants.PNG}, 
      {type: this.constants.GIF}, 
      {type: this.constants.BMP}];

    var validType = types.some(t => t.type === this.logo.type); //allow only images
    var validSize = this.logo.size < 2 * this.constants.ONE_MB; //max allowed file size is 2MB
    var validName = this.logo.name.length < 255; //max allowed file name is 255 characters

    if (this.logo != null && validSize && validType && validName) {
      this.completeEmployerForm.patchValue({ logo_id: this.logo.name });
      $("#logo").html(this.logo.name);
      this.logoMessage = ""
    }
    else {
      var errors = [validType? null : "Λάθος τύπος εικόνας. Επιτρεπτοί τύποι: .jpeg, .png, .gif, .bmp.", 
                    validSize? null : "Το μέγεθος της εικόνας πρέπει να είναι μέχρι 2 MB.", 
                    validName? null : "Το όνομα της εικόνας πρέπει να έχει μέχρι 255 χαρακτήρες."] 
      this.logoMessage = "* Σφάλματα: " + errors.filter(Boolean).join(' ');
    }
  }
  clearLogoSpan() {
    if (this.companyData.logo_id) {
      this.completeEmployerForm.patchValue({ logo_id: this.companyData.logo_id });
    }
    else {
      this.completeEmployerForm.patchValue({ logo_id: '' });
    }
    $("#logo").html("");
  }

  downLoadLogo(companyUuid) {
    const data = this.authService.downloadFile("company", "logo", new Date().getTime().toString(), companyUuid);
    this.fileUrl = data;
  }

  // for year_founded
  yearList() {

    let currentYear = new Date().getFullYear();
    let year = 1950;
    let num = currentYear - year + 1;

    for (let i = 0; i < num; i++) {
      this.numbers.push(currentYear);
      currentYear--;
    }
  }

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MetadataService } from '../MetadataService';
import { SnackBarService } from '../snack-bar.service';

@Component({
  selector: 'app-administrator-company-handling',
  templateUrl: './administrator-company-handling.component.html',
  styleUrls: ['./administrator-company-handling.component.css']
})
export class AdministratorCompanyHandlingComponent implements OnInit {
  companiesData: any;
  companyData: any;

  pickedCompaniesUuids = [];
  totalPages;

  modalMessage: string;
  modalTitle: string;
  selectedStatusForMultipleCompanies: string = null;


  /*
  --emit values: pending,approved,disappoved
  --emits a value on tab change to fetch the correct data and render them to the content
  --resolver has fetch the data for pending the first time so navigation subject emits null
  */
  selectedTabStatus: BehaviorSubject<string> = new BehaviorSubject(null);
  /* 
  --has the value of the tab 
  --flag for selected attribute - select input options
  --flag for ngClass select input
  */
  selectedTab = 'pending'

  metadata$: Observable<any>;
  locations$: Observable<Array<{ id: number; name: string; }>>;
  industries$: Observable<Array<{ id: number; name: string; }>>;

  // for pagination
  currentPage: any = 1;
  pageSize = 10;
  firstPage: number = 1;
  resData: any;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private metadataService: MetadataService,
    private router: Router,
    private snackBar: SnackBarService) {

  }

  ngOnInit() {

    this.selectedTabStatus.asObservable().subscribe((status) => {
      if (status !== null) {
        this.router.navigate(['/administrator/companyHandling'], { queryParams: { tab: status, page: 1 }, queryParamsHandling: 'merge' });
      }
    });

    this.activatedRoute.queryParamMap.subscribe(params => {

      let selectTabParam = params.get('tab') ? params.get('tab') : 'pending';
      this.currentPage = params.get('page') ? params.get('page') : this.firstPage;

      this.getCompaniesByActivationStatus(selectTabParam);
    });
    // this.companiesData = this.activatedRoute.snapshot.data['adminResolverCompany'].content;
    // this.totalPages = this.activatedRoute.snapshot.data['adminResolverCompany'].totalPages;
  }

  getCompaniesByActivationStatus(selectedTab){
      // for backend pagination starts from index 0 
      this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : 0 ;

      this.authService.getCompaniesByActivationStatus(selectedTab, this.currentPage, this.pageSize).subscribe((res) => {
        this.resData = res;
        this.companiesData = res.content;
        this.selectedTab = selectedTab;
      })
  }
  getMetaData() {
    this.metadata$ = this.metadataService.getMetaData()
      .pipe(shareReplay(1));

    this.industries$ = this.metadata$.pipe(
      map(metadata => metadata.industries.map(industry => ({ id: industry.id, text: industry.name })))
    );
    this.locations$ = this.metadata$.pipe(
      map(
        metadata => metadata.locations.map(location => ({ id: location.id, text: location.name }))
      )
    );
  }

  moreDetails(uuid: string, numberOfPublishedJobPosts) {
    this.authService.getCompanyInfo(uuid).subscribe((res) => {
      //toString - because if its zero-number *ngIf in template will be false (0 number is a falsy js value)
      res.numberOfActiveJobPosts = numberOfPublishedJobPosts.toString();
      this.companyData = res;
      this.getMetaData();
      this.getData();

      if (this.companyData.logo_id) {
        this.companyData.logo = this.authService.getLogo("company", "logo", this.companyData.uuid, new Date().getTime().toString());
      }
      else {
        this.companyData.logo = null;
      }
      $('#more-details-modal').modal('show');
    })
  }

  getData() {
    this.locations$.subscribe((locations: any[]) => {

      let x = locations.find(locItem => locItem.id === this.companyData.location_id);
      if (x) {
        this.companyData.location_name = x.text;
      }
    });

    this.industries$.subscribe((industries: any[]) => {
      let x = industries.find(item => item.id === this.companyData.industry_id);
      if (x) {
        this.companyData.industry_name = x.text;
      }
      else {
        this.companyData.industry_name = "";
      }
    });
  }

  changeActivationStatusOfOneCompany(companyUuid: string, changeEvent: any) {
    let activationStatus = changeEvent.srcElement.value;
    let selectElement = changeEvent.srcElement;
    this.authService.updateCompaniesActivationStatus([companyUuid], activationStatus).subscribe((res) => {
      this.onStatusChangedDomManipulation(selectElement);
    })
  }

  changeActivationStatusOfMultipleCompanies(activationStatus) {
    $('#confirm-modal').modal('hide');

    this.authService.updateCompaniesActivationStatus(this.pickedCompaniesUuids, activationStatus).subscribe(res => {
      this.pickedCompaniesUuids.forEach(existingCompanyUuid => {
        this.companiesData = this.companiesData.filter((companyData) => { return companyData.uuid !== existingCompanyUuid })
        $('#' + existingCompanyUuid).remove();
        
        this.currentPage = this.currentPage + 1;
        this.getCompaniesByActivationStatus(this.selectedTab);
      })
      this.pickedCompaniesUuids = [];

      switch (activationStatus) {
        case 'approved':
          this.snackBar.openSnackBar('Οι επιχεριρήσεις εγκρίθηκαν με επιτυχία', 3000, 'top', 'center', 'success-dialog')
          break;
        case 'disapproved':
          this.snackBar.openSnackBar('Οι επιχεριρήσεις απορρίφθηκαν', 3000, 'top', 'center', 'error-dialog')
          break;
      }
    },
      error => {
        this.snackBar.openSnackBar('Υπήρξε κάποιο πρόβλημα. Δοκιμάστε ξανά', 3000, 'top', 'center', 'error-dialog');
      })
  }

  openConfirmModal(activationStatus) {
    if (this.pickedCompaniesUuids.length !== 0) {
      this.selectedStatusForMultipleCompanies = activationStatus;
      switch (activationStatus) {
        case 'pending':
          this.modalTitle = 'Μεταφόρα στις Εκρεμμότητες '
          this.modalMessage = 'Είστε σίγουροι ότι θέλετε να μεταφέρετε τις επιλεγμένες επιχειρήσεις στο πίνακα εκρεμμοτήτων;'
        case 'approved':
          this.modalTitle = 'Έγκριση επιχειρήσεων'
          this.modalMessage = 'Είστε σίγουροι ότι θέλετε να εγκρίνετε τις επιλεγμένες επιχειρήσεις;'
          break;
        case 'disapproved':
          this.modalTitle = 'Απόρριψη επιχειρήσεων'
          this.modalMessage = 'Είστε σίγουροι ότι θέλετε να απορρίψετε τις επιλεγμένες επιχειρήσεις;'
          break;
      }
      $('#confirm-modal').modal('show');
    }
    else {
      this.snackBar.openSnackBar('Δεν έχουν επιλεγεί επιχειρήσεις', 3000, 'top', 'center', 'warning-dialog');
    }
  }

  onPickOrUnpickCompany(event) {
    let checkBoxElement = event.target;
    let companyUuid = checkBoxElement.value;

    if (checkBoxElement.checked) {
      this.pickedCompaniesUuids.push(companyUuid)
    }
    else {
      this.pickedCompaniesUuids = this.pickedCompaniesUuids.filter(existingCompanyUuid => {
        return existingCompanyUuid !== companyUuid
      })
    }

    console.log(this.pickedCompaniesUuids);
  }

  //change classes depends on selected status in DOM and open snackBar
  onStatusChangedDomManipulation(selectElement) {
    let selectValue = selectElement.value;

    //snackbar conf
    let duration = 3000;
    let horizontalPosition = 'center';
    let verticalPotition = 'bottom';
    let message;
    let panelClass;

    switch (selectValue) {
      case 'pending':
        $(selectElement).removeClass('disapproved-color approved-color')
        $(selectElement).addClass('pending-color')
        $(selectElement).parent().parent().removeClass('disapproved-color approved-color')
        $(selectElement).parent().parent().addClass('pending-color')
        message = 'Το προφίλ της επιχείρησης εκκρεμεί';
        panelClass = 'warning-dialog'
        break;
      case 'approved':
        $(selectElement).removeClass('disapproved-color pending-color')
        $(selectElement).addClass('approved-color')
        $(selectElement).parent().parent().removeClass('disapproved-color pending-color')
        $(selectElement).parent().parent().addClass('approved-color')
        message = 'Το προφίλ της επιχείρησης εγκρίνεται';
        panelClass = 'success-dialog'
        break;
      // Το προφίλ του υποψηφίου εγκρίνεται
      case 'disapproved':
        $(selectElement).removeClass('pending-color approved-color')
        $(selectElement).addClass('disapproved-color')
        $(selectElement).parent().parent().removeClass('pending-color approved-color')
        $(selectElement).parent().parent().addClass('disapproved-color')
        message = 'Το προφίλ της επιχείρησης απορρίπεται';
        panelClass = 'error-dialog';
        break;
    }

    this.snackBar.openSnackBar(message, duration, verticalPotition, horizontalPosition, panelClass);

  }

  tabNavigation(event) {
    let tabId = event.target.id;
    switch (tabId) {
      case 'pending-tab':
        this.selectedTabStatus.next('pending');
        break;
      case 'approved-tab':
        this.selectedTabStatus.next('approved');
        break;
      case 'disapproved-tab':
        this.selectedTabStatus.next('disapproved');
        break;
    }
  }

  //for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    console.log(this.currentPage + " currentPage on change")
    this.router.navigate(['/administrator/companyHandling'], { queryParams: { page: this.currentPage }, queryParamsHandling: 'merge' });
  }

}
